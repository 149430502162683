var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-typeahead-bootstrap", {
    ref: "datasetSelect",
    attrs: {
      placeholder: "Type in a dataset name ...",
      data: _vm.datasetOptions,
      serializer: (s) => s.description,
      maxMatches: 1000,
      minMatchingChars: 0,
      showOnFocus: true,
    },
    on: {
      hit: function ($event) {
        return _vm.onDatasetSelected($event)
      },
    },
    model: {
      value: _vm.userText,
      callback: function ($$v) {
        _vm.userText = $$v
      },
      expression: "userText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }