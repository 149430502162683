var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c(
          "b-table-simple",
          { attrs: { borderless: "", fixed: "", small: "" } },
          [
            _c(
              "b-tbody",
              [
                _c(
                  "b-tr",
                  [
                    _c("b-th", { staticStyle: { width: "150px" } }),
                    _c(
                      "b-td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "75px",
                          "background-color": "#D3DBED",
                        },
                      },
                      [_vm._v(_vm._s(_vm.commonBF))]
                    ),
                    _c(
                      "b-td",
                      {
                        staticClass: "text-left",
                        staticStyle: { width: "200px", color: "gray" },
                      },
                      [_vm._v("<--Common Variation BF")]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tr",
                  { staticStyle: { border: "3px solid white !important" } },
                  [
                    _c(
                      "b-th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "150px" },
                        attrs: { rowspan: "1" },
                      },
                      [_vm._v("X")]
                    ),
                    _c(
                      "b-td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "75px",
                          "background-color": "#fef8dc",
                        },
                      },
                      [_vm._v(_vm._s(_vm.rareBF))]
                    ),
                    _c(
                      "b-td",
                      {
                        staticClass: "text-left",
                        staticStyle: { width: "200px", color: "gray" },
                      },
                      [
                        this.exomeSignificant == false
                          ? _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [
                                _vm._v(" <--Rare Variation BF "),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.$bvModal.show("exampleModal")
                                      },
                                    },
                                  },
                                  [_vm._v("(Reset Prior Allelic variance)")]
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("<--Rare Variation BF")]),
                        _c("reset-prior-widget", {
                          attrs: { beta: 3, rareBF: this.rareBF },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tfoot",
              [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticClass: "text-right",
                        staticStyle: {
                          width: "150px",
                          "white-space": "nowrap",
                        },
                        attrs: { rowspan: "1" },
                      },
                      [_vm._v("HuGE Score:")]
                    ),
                    _c(
                      "b-td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "100px",
                          "border-top": "0.25px solid",
                          "border-color": "#D0D0D0",
                          "border-width": "thin",
                          "background-color": "#c4edc8",
                        },
                        attrs: { colspan: "1" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.hugeScore))])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }