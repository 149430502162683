var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        { staticClass: "text-right mt-2 mb-2" },
        [
          _c("data-download", {
            attrs: { data: _vm.tableData, filename: "gene_associations" },
          }),
        ],
        1
      ),
      _vm._m(0),
      _vm.pageKey && _vm.rows > 0
        ? _c("b-table", {
            attrs: {
              hover: "",
              small: "",
              responsive: "sm",
              items: _vm.tableData,
              fields:
                _vm.leadTableField !== "phenotype"
                  ? _vm.fields.filter((f) => f.key !== "group")
                  : _vm.fields,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(phenotype)",
                  fn: function (r) {
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "phenotype-gene-association",
                          attrs: { href: "javascript:;" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.phenotypeFormatter(
                                  _vm.phenotypeMap[r.item.phenotype]
                                )
                              ) +
                              " "
                          ),
                          _c("div", { staticClass: "options-4-actions" }, [
                            _c("div", [
                              _vm.phenotypeMap
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `/phenotype.html?phenotype=${r.item.phenotype}`,
                                      },
                                    },
                                    [_vm._v("Open phenotype page")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _vm.phenotypeMap
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `/region.html?phenotype=${r.item.phenotype}&chr=${r.item.chromosome}&start=${r.item.start}&end=${r.item.end}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Open region page with selected phenotype"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v("   "),
                    ]
                  },
                },
                {
                  key: "cell(gene)",
                  fn: function (r) {
                    return [
                      _c(
                        "a",
                        { attrs: { href: `/gene.html?gene=${r.item.gene}` } },
                        [_vm._v(" " + _vm._s(r.item.gene) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(link)",
                  fn: function (r) {
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "btn view-features-btn btn-secondary",
                          staticStyle: { color: "#ffffff !important" },
                          attrs: {
                            href: `/hugecalculator.html?gene=${r.item.gene}&phenotype=${r.item.phenotype}&prior=0.3696`,
                          },
                        },
                        [_vm._v("Open")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              540327637
            ),
          })
        : _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v(" No data available for this query. "),
                ],
                1
              ),
            ],
            1
          ),
      _c("b-pagination", {
        staticClass: "pagination-sm justify-content-center",
        attrs: { "total-rows": _vm.rows, "per-page": _vm.perPage },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticStyle: {
          "font-size": "12px",
          "white-space": "nowrap",
          position: "absolute",
          top: "15px",
        },
      },
      [
        _c("span", { staticClass: "compelling" }, [_vm._v("Compelling")]),
        _vm._v(" HuGE Score >= 350 | "),
        _c("span", { staticClass: "extreme" }, [_vm._v("Extreme")]),
        _vm._v(" >=100 | "),
        _c("span", { staticClass: "very-strong" }, [_vm._v("Very Strong")]),
        _vm._v(": >=30 | "),
        _c("span", { staticClass: "strong" }, [_vm._v("Strong")]),
        _vm._v(": >=10 | "),
        _c("span", { staticClass: "moderate" }, [_vm._v("Moderate")]),
        _vm._v(": >=3 |"),
        _c("span", { staticClass: "anecdotal" }, [_vm._v(" Anecdotal")]),
        _vm._v(": >1 | "),
        _c("span", { staticClass: "no-evidence" }, [_vm._v("No Evidence")]),
        _vm._v(": <=1"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }