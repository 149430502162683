var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "img" },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loaded && !_vm.loading,
            expression: "loaded && !loading",
          },
        ],
        ref: "im",
        staticClass: "card-img-top",
      }),
      _c(
        "b-skeleton-wrapper",
        {
          attrs: { loading: _vm.loading },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function () {
                return [
                  _c("b-skeleton-img", {
                    attrs: { "no-aspect": "", height: "300px" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.documentation && _vm.loaded
            ? _c(
                "p",
                { staticClass: "card-text" },
                [
                  _c("documentation", {
                    attrs: {
                      name: _vm.documentation,
                      "content-fill": _vm.contentFill,
                      contentMap: _vm.$store.state.bioPortal.documentations,
                    },
                  }),
                ],
                1
              )
            : _vm.unauthorized && !_vm.user
            ? _c(
                "b-alert",
                {
                  staticClass: "unauthorized",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v("Please "),
                  _c(
                    "a",
                    {
                      attrs: { href: "/login" },
                      on: { click: _vm.saveCurrentPage },
                    },
                    [_vm._v("log in")]
                  ),
                  _vm._v(
                    " with an authorized Google account to see this content. "
                  ),
                ],
                1
              )
            : _vm.unauthorized && !!_vm.user
            ? _c(
                "b-alert",
                {
                  staticClass: "unauthorized",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v("You don't have permission to view this content. "),
                  _c("br"),
                  _vm._v(
                    "Please contact us if you believe you should've given access. "
                  ),
                ],
                1
              )
            : !!_vm.customFailureMsg
            ? _c(
                "b-alert",
                {
                  staticClass: "unauthorized",
                  attrs: { show: "", variant: "warning" },
                },
                [_vm._v(" " + _vm._s(this.customFailureMsg) + " ")]
              )
            : _vm.failed
            ? _c(
                "b-alert",
                {
                  staticClass: "failed",
                  attrs: { show: "", variant: "danger" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v(" Failed to load "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }