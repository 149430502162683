var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: _vm.isSubtable ? "pigean-subtable" : "",
      attrs: { id: "pigean-gene" },
    },
    [
      _vm.tableData.length > 0
        ? _c(
            "div",
            [
              !_vm.isSubtable
                ? _c(
                    "div",
                    { staticClass: "text-right mb-2" },
                    [
                      _c("data-download", {
                        attrs: { data: _vm.probData, filename: "pigean_gene" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", {
                staticClass: "table-total-rows",
                domProps: { innerHTML: _vm._s("Total rows: " + _vm.rows) },
              }),
              _c("b-table", {
                attrs: {
                  hover: _vm.isSubtable,
                  small: "",
                  responsive: "sm",
                  items: _vm.tableData,
                  fields: _vm.probFields,
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  "sort-by": _vm.sortBy,
                  "sort-desc": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(label)",
                      fn: function (r) {
                        return [
                          !!r.item.label
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      r.item.label.length > 50
                                        ? `${r.item.label.slice(0, 50)}...`
                                        : r.item.label
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(gene)",
                      fn: function (r) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pigean/gene.html?gene=${r.item.gene}${_vm.suffix}`,
                              },
                            },
                            [_vm._v(" " + _vm._s(r.item.gene) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(phenotype)",
                      fn: function (r) {
                        return [
                          !!_vm.phenotypeMap[r.item.phenotype]
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: `/pigean/phenotype.html?phenotype=${r.item.phenotype}${_vm.suffix}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.phenotypeFormatter(
                                          _vm.phenotypeMap[r.item.phenotype]
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(r.item.phenotype))]),
                        ]
                      },
                    },
                    {
                      key: "cell(gene_set)",
                      fn: function (r) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pigean/geneset.html?geneset=${r.item.gene_set}${_vm.suffix}`,
                              },
                            },
                            [_vm._v(" " + _vm._s(r.item.gene_set) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(phewasPlot)",
                      fn: function (row) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.phewasPlotShow(row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.item.phewasActive ? "Hide" : "Show"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(expand)",
                      fn: function (row) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetails(row, 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(row.detailsShowing ? "Hide" : "Show") +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(expand1)",
                      fn: function (row) {
                        return [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                split: "",
                                right: "",
                                text:
                                  row.detailsShowing &&
                                  row.item.subtableActive === 1
                                    ? "Hide"
                                    : "Show",
                                variant: "outline-primary",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetails(row, 1)
                                },
                              },
                            },
                            [
                              _c(
                                "b-dropdown-header",
                                { attrs: { id: "dropdown-header-label" } },
                                [_vm._v(" Top 5 Genes ")]
                              ),
                              _vm._l(
                                row.item.top_genes.split(";"),
                                function (gene) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: gene,
                                      attrs: {
                                        href: `/pigean/gene.html?gene=${gene}${_vm.suffix}`,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(gene) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(expand2)",
                      fn: function (row) {
                        return [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                split: "",
                                right: "",
                                text:
                                  row.detailsShowing &&
                                  row.item.subtableActive === 2
                                    ? "Hide"
                                    : "Show",
                                variant: "outline-primary",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetails(row, 2)
                                },
                              },
                            },
                            [
                              _c(
                                "b-dropdown-header",
                                { attrs: { id: "dropdown-header-label" } },
                                [_vm._v(" Top 5 Gene Sets ")]
                              ),
                              _vm._l(
                                row.item.top_gene_sets.split(";"),
                                function (geneSet) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: geneSet,
                                      attrs: {
                                        href: `/pigean/geneset.html?geneset=${geneSet}${_vm.suffix}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            geneSet.length > 40
                                              ? `${geneSet.slice(0, 40)}...`
                                              : geneSet
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "row-details",
                      fn: function (row) {
                        return [
                          row.item.phewasActive &&
                          _vm.phewasData[_vm.phewasKey(row.item)] &&
                          _vm.phewasData[_vm.phewasKey(row.item)].length > 0
                            ? _c("research-phewas-plot", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "canvas-id": `pigean_${
                                    row.item.phenotype
                                  }_${_vm.generateId(row.item.label)}`,
                                  "plot-name": `PIGEAN_${row.item.phenotype}`,
                                  "phenotypes-data":
                                    _vm.phewasData[_vm.phewasKey(row.item)],
                                  "phenotype-map":
                                    _vm.phenotypeMap ||
                                    _vm.$store.state.bioPortal.phenotypeMap,
                                  linkPhenotypes: true,
                                  isPigean: true,
                                  colors: _vm.plotColors,
                                  "render-config": _vm.phewasRenderConfig,
                                  utils: _vm.utilsBox,
                                  "native-dl-btn": false,
                                  top1500: true,
                                },
                              })
                            : _vm._e(),
                          row.item.subtableActive === 2 &&
                          _vm.subtable2Data[_vm.subtableKey(row.item)] &&
                          _vm.subtable2Data[_vm.subtableKey(row.item)].length >
                            0
                            ? _c("pigean-table", {
                                attrs: {
                                  pigeanData:
                                    _vm.subtable2Data[
                                      _vm.subtableKey(row.item)
                                    ],
                                  config: {
                                    fields: _vm.config.subtable2Fields,
                                  },
                                  isSubtable: true,
                                },
                              })
                            : _vm._e(),
                          row.item.subtableActive === 1 &&
                          _vm.subtableData[_vm.subtableKey(row.item)] &&
                          _vm.subtableData[_vm.subtableKey(row.item)].length > 0
                            ? _c("pigean-table", {
                                attrs: {
                                  pigeanData:
                                    _vm.subtableData[_vm.subtableKey(row.item)],
                                  config: { fields: _vm.config.subtableFields },
                                  isSubtable: true,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4079943794
                ),
              }),
              _c("b-pagination", {
                staticClass: "pagination-sm justify-content-center",
                attrs: {
                  "total-rows": _vm.tableData.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v(" No data available for this query. "),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }