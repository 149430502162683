var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "filter-control-template",
    {
      staticClass: "filter-col-sm",
      attrs: {
        field: _vm.field,
        type: "string",
        predicate: _vm.predicate,
        labelFormatter: _vm.labelFormatter,
        pillFormatter: _vm.pillFormatter,
        options: _vm.options,
        multiple: false,
        computedField: _vm.computedField,
        color: _vm.color,
        placeholder: _vm.placeholder,
      },
      on: {
        "input-change": function ($event) {
          return _vm.$emit("input-change", $event)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }