var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("autocomplete", {
    ref: "genesetSelect",
    attrs: {
      placeholder: !_vm.$store.state.genesetToQuery
        ? "Search gene set"
        : _vm.$store.state.genesetToQuery,
      matches: _vm.matchingGenesets,
    },
    on: {
      "input-change": function ($event) {
        return _vm.lookupGenesets($event)
      },
      "item-select": function ($event) {
        return _vm.selectGeneset($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }