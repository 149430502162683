var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.tableData.length > 0
      ? _c(
          "div",
          [
            _c(
              "b-row",
              { staticClass: "mb-2" },
              [
                _c("b-col", { staticClass: "d-flex align-items-center" }, [
                  _c("strong", { staticClass: "mr-2" }, [
                    _vm._v("Total Rows:"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.tableData.length)),
                ]),
                _c(
                  "b-col",
                  { staticClass: "text-right" },
                  [
                    _c("data-download", {
                      attrs: {
                        data: _vm.sortedAssociations,
                        filename: "associations",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-table", {
              class: !!_vm.showBottomLine ? "assoc-table-bottom-line" : "",
              attrs: {
                hover: "",
                small: "",
                responsive: "sm",
                items: _vm.groupedAssociations,
                fields: _vm.fields,
                "per-page": _vm.perPage,
                "current-page": _vm.currentPage,
                "sort-null-last": true,
                sortable: true,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "thead-top",
                    fn: function (data) {
                      return [
                        _vm.showBottomLine
                          ? _c("b-th", {
                              staticClass: "bl-bar",
                              attrs: { colspan: 1 },
                            })
                          : _vm._e(),
                        _c(
                          "b-th",
                          { attrs: { colspan: !!_vm.showChiSquared ? 6 : 5 } },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Variant"),
                            ]),
                          ]
                        ),
                        _vm._l(_vm.phenotypes, function (phenotype, i) {
                          return _c(
                            "b-th",
                            {
                              key: phenotype.name,
                              staticClass: "reference",
                              class: "color-" + (i + 1),
                              attrs: { colspan: "2" },
                            },
                            [
                              _c("span", { staticStyle: { color: "white" } }, [
                                _vm._v(_vm._s(phenotype.description)),
                              ]),
                            ]
                          )
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(position)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/region.html?phenotype=${
                                _vm.phenotypes[0].name
                              }&chr=${r.item.chromosome}&start=${
                                r.item.position - 50000
                              }&end=${r.item.position + 50000}`,
                            },
                          },
                          [_vm._v(_vm._s(_vm.locusFormatter(r.item)))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(allele)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/variant.html?variant=${r.item.varId}`,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.alleleFormatter(r.item)) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(dbSNP)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/variant.html?variant=${r.item.dbSNP}`,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.dbSNPFormatter(r.item)) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(consequence)",
                    fn: function (r) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.consequenceFormatter(r.item.consequence)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(genes)",
                    fn: function (r) {
                      return _vm._l(r.item.nearest, function (gene) {
                        return _c(
                          "a",
                          {
                            key: gene,
                            staticClass: "item",
                            attrs: { href: `/gene.html?gene=${gene}` },
                          },
                          [_vm._v(_vm._s(gene))]
                        )
                      })
                    },
                  },
                  {
                    key: "cell(maf)",
                    fn: function (r) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.mafFormatter(r.item.maf)) + " "
                        ),
                      ]
                    },
                  },
                  _vm._l(_vm.phenotypes, function (p) {
                    return {
                      key: _vm.phenotypeBetaColumn(p),
                      fn: function (r) {
                        return [
                          _c(
                            "span",
                            {
                              key: p.name,
                              class: `effect ${
                                r.item[`${p.name}:beta`] < 0
                                  ? "negative"
                                  : "positive"
                              }`,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !!r.item[`${p.name}:beta`]
                                      ? r.item[`${p.name}:beta`] < 0
                                        ? "▼"
                                        : "▲"
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.effectFormatter(
                                    p.dichotomous
                                      ? Math.exp(r.item[`${p.name}:beta`])
                                      : r.item[`${p.name}:beta`]
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    }
                  }),
                  _vm._l(_vm.phenotypes, function (p) {
                    return {
                      key: _vm.phenotypePValueColumn(p),
                      fn: function (r) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.pValueFormatter(r.item[`${p.name}:pValue`])
                            )
                          ),
                        ]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            }),
            _c("b-pagination", {
              staticClass: "pagination-sm justify-content-center",
              attrs: {
                "total-rows": _vm.groupedAssociations.length,
                "per-page": _vm.perPage,
              },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        )
      : _c("div", [
          _vm.associations.length > 0
            ? _c("h4", [
                _vm._v(" No overlapping associations across phenotypes "),
              ])
            : _c("h4", [_vm._v("No associations")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }