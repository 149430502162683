var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.associations
    ? _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPlot,
                expression: "showPlot",
              },
            ],
          },
          [
            _vm.plotData.length > 0
              ? _c("research-m-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.assocPlotConfig,
                  },
                })
              : _vm._e(),
            _c("center", { staticStyle: { "margin-bottom": "30px" } }, [
              _c(
                "b",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.showChiSquared,
                      expression: "!!showChiSquared",
                    },
                  ],
                },
                [
                  _vm._v(" Combined P-Value(Χ²) across "),
                  _vm._l(_vm.phenotypes, function (p) {
                    return _c(
                      "a",
                      {
                        staticClass: "item",
                        attrs: { href: `/phenotype.html?phenotype=${p}` },
                      },
                      [_vm._v(_vm._s(_vm.phenotypeMap[p].description))]
                    )
                  }),
                ],
                2
              ),
            ]),
          ],
          1
        ),
        _vm.tableData.length > 0
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "text-right mb-2" },
                  [
                    _c("data-download", {
                      attrs: {
                        data: _vm.groupedAssociations,
                        filename: "gene_table",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-table", {
                  attrs: {
                    hover: "",
                    small: "",
                    responsive: "sm",
                    items: _vm.groupedAssociations,
                    fields: _vm.fields,
                    "per-page": _vm.rowsPerPage,
                    "current-page": _vm.currentPage,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "thead-top",
                        fn: function (data) {
                          return [
                            _c(
                              "b-th",
                              {
                                attrs: {
                                  colspan: !!_vm.showChiSquared ? 2 : 1,
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Matching genes: " +
                                      _vm._s(_vm.groupedAssociations.length) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._l(_vm.phenotypes, function (phenotype, i) {
                              return _c(
                                "b-th",
                                {
                                  key: phenotype,
                                  staticClass: "reference",
                                  class: "color-" + (i + 1),
                                  attrs: { colspan: "1" },
                                },
                                [
                                  _vm.phenotypeMap[phenotype]
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.phenotypeMap[phenotype]
                                                  .description
                                              ) +
                                              _vm._s(
                                                ": " +
                                                  _vm.genesPerPhenotypes[
                                                    phenotype
                                                  ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(geneName)",
                        fn: function (r) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `/gene.html?gene=${r.item.gene}`,
                                },
                              },
                              [_vm._v(" " + _vm._s(r.item.gene) + " ")]
                            ),
                          ]
                        },
                      },
                      _vm._l(_vm.phenotypes, function (p) {
                        return {
                          key: _vm.phenotypePValueColumn(p),
                          fn: function (r) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.pValueFormatter(r.item[`${p}:pValue`])
                                )
                              ),
                            ]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
                _c("b-pagination", {
                  staticClass: "pagination-sm justify-content-center",
                  attrs: {
                    "total-rows": _vm.groupedAssociations.length,
                    "per-page": _vm.rowsPerPage,
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            )
          : _c("div", [
              _vm.associations.length > 0
                ? _c("h4", [_vm._v("No overlapping associations")])
                : _c("h4", [_vm._v("No associations")]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }