var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-dropdown",
    {
      attrs: {
        variant: "secondary",
        right: "",
        size: "sm",
        text: "Download data",
      },
    },
    [
      _c("b-dropdown-text", [_vm._v("Save file as")]),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.downloadCsv()
            },
          },
        },
        [_vm._v("CSV")]
      ),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.downloadTsv()
            },
          },
        },
        [_vm._v("TSV")]
      ),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.downloadJson()
            },
          },
        },
        [_vm._v("JSON")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }