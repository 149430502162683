var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.rows > 0
        ? _c("div", [
            _c("div", {
              staticClass: "table-total-rows",
              domProps: { innerHTML: _vm._s("Total rows: " + _vm.rows) },
            }),
            _c(
              "div",
              { staticClass: "text-right mb-2" },
              [
                _c("data-download", {
                  attrs: {
                    data: _vm.items,
                    filename: `mouse_summary_${_vm.pageParam}`,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("b-table", {
        attrs: {
          small: "",
          items: _vm.filteredData,
          fields: _vm.fields,
          "sort-by": !_vm.isGenePage ? "gene" : "tissue",
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          "sort-compare": _vm.sortRows,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(tissue)",
            fn: function (row) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/mouse_diff_exp.html?gene=${row.item.gene}&tissue=${row.item.tissue}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(row.item.tissue) + " ")]
                ),
              ]
            },
          },
          {
            key: "cell(gene)",
            fn: function (row) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/mouse_diff_exp.html?gene=${row.item.gene}&tissue=${row.item.tissue}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(row.item.gene) + " ")]
                ),
              ]
            },
          },
          {
            key: "cell(gene_id)",
            fn: function (row) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `https://useast.ensembl.org/Mus_musculus/Gene/Summary?db=core;g=${row.item.gene_id}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(row.item.gene_id) + " ")]
                ),
              ]
            },
          },
          {
            key: "cell(gene_region)",
            fn: function (row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      `${row.item.chromosome}:${row.item.start}-${row.item.end}`
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("b-pagination", {
        staticClass: "pagination-sm justify-content-center",
        attrs: { "total-rows": _vm.rows, "per-page": _vm.perPage },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }