import { render, staticRenderFns } from "./FilterEffectDirection.vue?vue&type=template&id=5f2f8130"
import script from "./FilterEffectDirection.vue?vue&type=script&lang=js"
export * from "./FilterEffectDirection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/Portal (Dev)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f2f8130')) {
      api.createRecord('5f2f8130', component.options)
    } else {
      api.reload('5f2f8130', component.options)
    }
    module.hot.accept("./FilterEffectDirection.vue?vue&type=template&id=5f2f8130", function () {
      api.rerender('5f2f8130', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/criterion/FilterEffectDirection.vue"
export default component.exports