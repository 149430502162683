var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "greater-less-filter" },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterGreater,
              expression: "filterGreater",
            },
          ],
          staticClass: "form-control form-control-sm",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.filterGreater = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { domProps: { value: true } }, [_vm._v("≥")]),
          _c("option", { domProps: { value: false } }, [_vm._v("≤")]),
        ]
      ),
      _c(
        "filter-control-template",
        {
          attrs: {
            field: _vm.field,
            type: "number",
            predicate: (number, bound) =>
              _vm.filterGreater ? number >= bound : number <= bound,
            pillFormatter: (filterDefinition) =>
              _vm.filterGreater
                ? `${_vm.label} ≥ ${filterDefinition.threshold}`
                : `${_vm.label} ≤ ${filterDefinition.threshold}`,
            placeholder: `${_vm.label} ${_vm.filterGreater ? "≥" : "≤"}`,
            color: _vm.color,
            computedField: _vm.computedField,
            multiple: false,
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.field))]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }