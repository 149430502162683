var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.elementid } }, [
    _c("div", { staticClass: "color-bar-plot-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "span",
          {
            staticClass: "center",
            staticStyle: { "font-weight": "bold", "white-space": "nowrap" },
          },
          [
            _vm._v(
              "HuGE score " +
                _vm._s(this.score) +
                " falls in " +
                _vm._s(this.category) +
                " evidence range"
            ),
          ]
        ),
      ]),
      _c(
        "span",
        {
          staticStyle: {
            "font-size": "12px",
            padding: "40px 145px 40px 145px",
            "white-space": "nowrap",
          },
        },
        [
          _vm._v(
            "Compelling: HuGE Score >= 350 | Extreme: >=100 | Very Strong: >=30 | Strong: >=10 | Moderate: >=3 | Anecdotal: >1 | No Evidence: <=1"
          ),
        ]
      ),
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Compelling"
            ? _c("div", { staticClass: "arrow-up causalclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Extreme"
            ? _c("div", { staticClass: "arrow-up strongclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Very Strong"
            ? _c("div", { staticClass: "arrow-up moderateclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Strong"
            ? _c("div", { staticClass: "arrow-up possibleclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Moderate"
            ? _c("div", { staticClass: "arrow-up potentialclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "Anecdotal"
            ? _c("div", { staticClass: "arrow-up weakclass" })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "each-bar-section" }, [
          this.category == "No"
            ? _c("div", { staticClass: "arrow-up noEvidenceclass" })
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row color-bars-wrapper" }, [
      _c("div", { staticClass: "each-bar-section variationStrong" }, [
        _vm._v("Compelling"),
      ]),
      _c("div", { staticClass: "each-bar-section variationModerate" }, [
        _vm._v("Extreme"),
      ]),
      _c("div", { staticClass: "each-bar-section variationPossible" }, [
        _vm._v("Very Strong"),
      ]),
      _c("div", { staticClass: "each-bar-section variationPotential" }, [
        _vm._v("Strong"),
      ]),
      _c("div", { staticClass: "each-bar-section variationWeak" }, [
        _vm._v("Moderate"),
      ]),
      _c("div", { staticClass: "each-bar-section variationEquivocal" }, [
        _vm._v("Anecdotal"),
      ]),
      _c("div", { staticClass: "each-bar-section variationNoEvidence" }, [
        _vm._v("No Evidence"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }