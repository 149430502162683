var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "c2ct" } }, [
    _vm.rows > 0
      ? _c(
          "div",
          [
            _c("div", [
              _c("div", {
                staticClass: "table-total-rows",
                domProps: { innerHTML: _vm._s("Total rows: " + _vm.rows) },
              }),
              _c(
                "div",
                { staticClass: "text-right mb-2" },
                [
                  _c("data-download", {
                    attrs: {
                      data: _vm.c2ctData,
                      filename: `c2ct_${_vm.phenotype}`,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("b-table", {
              attrs: {
                hover: "",
                small: "",
                responsive: "sm",
                items: _vm.tableData,
                fields: _vm.fields,
                "per-page": _vm.perPage,
                "current-page": _vm.currentPage,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "head(posteriorProbability)",
                    fn: function (data) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { title: "Higher means greater overlap." },
                          },
                          [_vm._v(_vm._s(data.label) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(Q)",
                    fn: function (data) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              title:
                                "This metric combines specificity and overlap probability. Higher means more confidence that the SNP overlaps a specific cell type.",
                            },
                          },
                          [_vm._v(_vm._s(data.label) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(variantSifter)",
                    fn: function () {
                      return [
                        _vm._v(
                          " Open Variant Sifter (region: lead SNP ± 200kb) "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(tissue)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/tissue.html?tissue=${r.item.tissue}`,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.tissueFormatter(r.item.tissue)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(chromosome)",
                    fn: function (r) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${r.item.chromosome}:${r.item.clumpStart}-${r.item.clumpEnd}`
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(overlapLeadSNP)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/variant.html?variant=${r.item.overlapLeadSNP}`,
                            },
                          },
                          [_vm._v(" " + _vm._s(r.item.overlapLeadSNP) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(variantSifter)",
                    fn: function (r) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: _vm.exploreVariantSifter(r.item) } },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-sm",
                                attrs: { type: "button" },
                              },
                              [_vm._v(" Open ")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                386489694
              ),
            }),
            _c("b-pagination", {
              staticClass: "pagination-sm justify-content-center",
              attrs: { "total-rows": _vm.rows, "per-page": _vm.perPage },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-alert",
              {
                staticClass: "text-center",
                attrs: { show: "", variant: "warning" },
              },
              [
                _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                _vm._v(" No data available for this query. "),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }