var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.filteredAssociations.length > 0
    ? _c(
        "div",
        { staticClass: "EGLT-table fiftytwo" },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "div",
                { staticClass: "text-right mt-2 mb-2 border-0" },
                [
                  _c("data-download", {
                    attrs: {
                      data: _vm.associations,
                      filename: "rare_variant_gene_associations",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "top-level-header" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "top-level-header-item",
                      attrs: { cols: "4" },
                    },
                    [_vm._v("Phenotype")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "top-level-header-item",
                      attrs: { cols: "2" },
                    },
                    [_vm._v("pValue")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "top-level-header-item",
                      attrs: { cols: "2" },
                    },
                    [_vm._v("Beta")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "top-level-header-item",
                      attrs: { cols: "2" },
                    },
                    [_vm._v("Odds Ratio")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "top-level-header-item",
                      attrs: { cols: "2" },
                    },
                    [_vm._v("View")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.paginatedAssociations, function (row, i) {
                return [
                  _vm.phenotypeMap[row.phenotype]
                    ? _c(
                        "b-row",
                        {
                          key: row.phenotype + i,
                          staticClass: "data top-level-value",
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "top-level-value-item",
                              attrs: { cols: "4" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: `/phenotype.html?phenotype=${row.phenotype}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.phenotypeMap[row.phenotype]
                                        .description
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "top-level-value-item pValue",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(_vm._s(_vm.pValueFormatter(row.pValue)))]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "top-level-value-item beta",
                              attrs: { cols: "2" },
                            },
                            [
                              !_vm.phenotypeMap[row.phenotype].dichotomous
                                ? [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          row.beta < 0
                                            ? "effect negative"
                                            : "effect positive",
                                      },
                                      [_vm._v(_vm._s(row.beta < 0 ? "▼" : "▲"))]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.effectFormatter(row.beta))
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "top-level-value-item beta",
                              attrs: { cols: "2" },
                            },
                            [
                              !!_vm.phenotypeMap[row.phenotype].dichotomous
                                ? [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          Math.exp(row.beta) < 1
                                            ? "effect negative"
                                            : "effect positive",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            Math.exp(row.beta) < 1 ? "▼" : "▲"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.effectFormatter(
                                            Math.exp(row.beta)
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "top-level-value-item",
                              attrs: { cols: "2" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "view-features-btn",
                                  attrs: { disabled: !row.masks.length },
                                  on: {
                                    click: function ($event) {
                                      _vm.showFeatures(
                                        (_vm.currentPage - 1) * _vm.perPage + i
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Masks + Plot")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !!_vm.phenotypeMap[row.phenotype]
                    ? _c("mask-table", {
                        key: (_vm.currentPage - 1) * _vm.perPage + i,
                        attrs: {
                          "mask-data": row.masks,
                          index: (_vm.currentPage - 1) * _vm.perPage + i,
                          dichotomous:
                            !!_vm.phenotypeMap[row.phenotype].dichotomous,
                          "is-hidden": true,
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c("b-pagination", {
            staticClass: "pagination-sm justify-content-center mt-3",
            attrs: {
              "total-rows": _vm.filteredAssociations.length,
              "per-page": _vm.perPage,
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "b-alert",
            {
              staticClass: "text-center",
              attrs: { show: "", variant: "warning" },
            },
            [
              _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
              _vm._v(" No data available for this query. "),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }