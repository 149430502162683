var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "filter-control-template",
    {
      staticClass: "filter-col-md",
      attrs: {
        field: _vm.field,
        placeholder: _vm.placeholder,
        predicate: _vm.predicate,
        pillFormatter: _vm.pillFormatter,
        options: _vm.options,
        color: _vm.color,
        multiple: false,
        inclusive: _vm.inclusive,
        splitBy: _vm.splitBy,
        computedField: _vm.computedField,
      },
      on: {
        "input-change": function ($event) {
          return _vm.$emit("input-change", $event)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }