var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      staticClass: "modal fade",
      attrs: {
        "header-class": "my-header-class",
        id: "exampleModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalLabel",
        "aria-hidden": "true",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "w-100" }, [
                _c("p", { staticStyle: {} }, [_vm._v("Rare Variation")]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "b-table-simple",
        { attrs: { borderless: "", fixed: "", small: "", responsive: "" } },
        [
          _c(
            "b-tbody",
            [
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { width: "100px" } }),
                  _c(
                    "b-td",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        width: "150px",
                        "background-color": "#fef8dc",
                      },
                    },
                    [_vm._v("3.36")]
                  ),
                  _c(
                    "b-td",
                    {
                      staticClass: "text-left",
                      staticStyle: { width: "200px", color: "gray" },
                    },
                    [_vm._v("<--Burden Association Summary Statistics")]
                  ),
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "text-right",
                      staticStyle: { width: "100px" },
                      attrs: { rowspan: "1" },
                    },
                    [_vm._v("X")]
                  ),
                  _c(
                    "b-td",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "150px" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.$store.state.prior,
                            expression: "$store.state.prior",
                            modifiers: { number: true },
                          },
                        ],
                        staticStyle: {
                          "background-color": "#fef8dc",
                          width: "100%",
                        },
                        attrs: {
                          type: "number",
                          placeholder: "Prior Variance",
                          id: "prior_variance_input",
                        },
                        domProps: { value: _vm.$store.state.prior },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$store.state,
                              "prior",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-td",
                    {
                      staticClass: "text-left",
                      staticStyle: { width: "200px", color: "gray" },
                    },
                    [_vm._v("<--Prior Allelic Variance")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tfoot",
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "text-right",
                      staticStyle: { width: "100px" },
                      attrs: { rowspan: "1" },
                    },
                    [_vm._v("Bayes Factor:")]
                  ),
                  _c(
                    "b-td",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        width: "100px",
                        "border-top": "0.25px solid",
                        "border-color": "#D0D0D0",
                        "border-width": "thin",
                        "background-color": "#fef8dc",
                      },
                      attrs: { colspan: "1" },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.rareBF))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }