var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-typeahead-bootstrap", {
    ref: "autocomplete",
    attrs: {
      data: _vm.lookupOptions,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      serializer: _vm.labelFormatter,
      showOnFocus: true,
      maxMatches: 1000,
    },
    on: {
      hit: function ($event) {
        return _vm.onAutoCompleteItemSelected($event)
      },
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.onUserEnterNonAutoCompleteItem.apply(null, arguments)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "suggestion",
        fn: function ({ data, htmlText }) {
          return [
            _c("span", { domProps: { innerHTML: _vm._s(htmlText) } }),
            _vm._v("  "),
            _vm.secondaryKey
              ? _c("small", { staticClass: "text-secondary" }, [
                  _vm._v(_vm._s(data[_vm.secondaryKey])),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.userInput,
      callback: function ($$v) {
        _vm.userInput = $$v
      },
      expression: "userInput",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }