var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "variant-search" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "9" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableData.length,
                    expression: "tableData.length",
                  },
                ],
                staticClass: "legends",
              },
              [
                _c("strong", { staticClass: "mr-2" }, [_vm._v("Impact:")]),
                _c(
                  "b-btn",
                  {
                    staticClass: "mr-1 btn-mini",
                    attrs: {
                      disabled: "",
                      variant: "outline-danger",
                      size: "sm",
                    },
                  },
                  [_vm._v("HIGH")]
                ),
                _c(
                  "b-btn",
                  {
                    staticClass: "mr-1 btn-mini",
                    attrs: {
                      disabled: "",
                      variant: "outline-warning",
                      size: "sm",
                    },
                  },
                  [_vm._v("MODERATE")]
                ),
                _c(
                  "b-btn",
                  {
                    staticClass: "mr-1 btn-mini",
                    attrs: {
                      disabled: "",
                      variant: "outline-success",
                      size: "sm",
                    },
                  },
                  [_vm._v("LOW")]
                ),
                _c(
                  "b-btn",
                  {
                    staticClass: "btn-mini",
                    attrs: {
                      disabled: "",
                      variant: "outline-secondary",
                      size: "sm",
                    },
                  },
                  [_vm._v("MODIFIER")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "ml-3",
                    attrs: {
                      name: "sort-impacts",
                      switch: "",
                      inline: "",
                      size: "sm",
                    },
                    model: {
                      value: _vm.sortByImpacts,
                      callback: function ($$v) {
                        _vm.sortByImpacts = $$v
                      },
                      expression: "sortByImpacts",
                    },
                  },
                  [_vm._v(" Rank by Impact ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right mb-2" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "btn btn-secondary btn-sm",
                  on: {
                    click: function ($event) {
                      return _vm.showHideElement("filter_pop_up")
                    },
                  },
                },
                [_vm._v("Filter Results")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right mb-2" },
            [
              _vm.tableData.length
                ? _c("data-download", {
                    attrs: { data: _vm.tableData, filename: "variants" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { attrs: { id: "filter_pop_up_example" } }, [
              _c(
                "div",
                { staticClass: "hidden", attrs: { id: "filter_pop_up" } },
                [
                  _c("div", [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-bottom": "20px",
                        },
                      },
                      [_vm._v(" Filter variants ")]
                    ),
                    _c("form", [
                      _c("fieldset", [
                        _c("h6", [_vm._v("Select impact")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "15px",
                              "margin-bottom": "15px",
                            },
                          },
                          [
                            _vm._l(_vm.disablebtn, function (key, value) {
                              return [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    key: key + value,
                                    attrs: {
                                      name: "impact",
                                      disabled: key,
                                      value: value,
                                      inline: "",
                                    },
                                    model: {
                                      value: _vm.filters["impacts"],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "impacts", $$v)
                                      },
                                      expression: "filters['impacts']",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass: "mr-1 btn-mini",
                                        attrs: {
                                          disabled: "",
                                          variant: _vm.disablebtnstyle[value],
                                          size: "sm",
                                        },
                                      },
                                      [_vm._v(_vm._s(value))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "25px",
                      },
                    },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "btn btn-warning btn-sm",
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function ($event) {
                              return _vm.showHideElement("filter_pop_up")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "b-btn",
                        {
                          staticClass: "btn btn-success btn-sm",
                          attrs: {
                            disabled:
                              !_vm.filters["phenotypes"].length ||
                              !_vm.filters["impacts"].length,
                          },
                          on: {
                            click: function ($event) {
                              _vm.addfilter()
                              _vm.showHideElement("filter_pop_up")
                            },
                          },
                        },
                        [_vm._v("Apply filter")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length,
              expression: "tableData.length",
            },
          ],
        },
        [
          _c("b-table", {
            attrs: {
              hover: "",
              small: "",
              "sort-icon-left": "",
              responsive: "sm",
              fields: _vm.fields,
              items: _vm.tableData,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            scopedSlots: _vm._u([
              {
                key: "thead-top",
                fn: function (data) {
                  return [
                    _c(
                      "b-tr",
                      [
                        _c("b-th", { attrs: { colspan: "3" } }, [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Variant, dbSNP, Consequence"),
                          ]),
                        ]),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "3", variant: "secondary" },
                          },
                          [_vm._v("Allele")]
                        ),
                        _c("b-th", [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Max AF"),
                          ]),
                        ]),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "2", variant: "secondary" },
                          },
                          [_vm._v("Heterozygous")]
                        ),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            staticStyle: { "border-left": "1px solid #dee2e6" },
                            attrs: { colspan: "2", variant: "secondary" },
                          },
                          [_vm._v("Homozygous")]
                        ),
                        _c("b-th", [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("View VEP Data"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(varId)",
                fn: function (data) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/variant.html?variant=${data.item.varId}`,
                        },
                      },
                      [_vm._v(_vm._s(data.item.varId))]
                    ),
                  ]
                },
              },
              {
                key: "cell(dbSNP)",
                fn: function (data) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/variant.html?variant=${data.item.dbSNP}`,
                        },
                      },
                      [_vm._v(_vm._s(data.item.dbSNP))]
                    ),
                  ]
                },
              },
              {
                key: "cell(max_consequence)",
                fn: function (data) {
                  return [
                    data.item.Max_Impact
                      ? _c(
                          "div",
                          {
                            staticClass: "border-color",
                            class: data.item.Max_Impact,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.max_consequence.substring(
                                    1,
                                    data.item.max_consequence.length - 1
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "border-color NONE" }),
                  ]
                },
              },
              {
                key: "cell(view)",
                fn: function (data) {
                  return [
                    !data.item.consequence
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "btn-mini",
                            attrs: {
                              disabled: "",
                              size: "sm",
                              variant: "outline-secondary",
                            },
                          },
                          [_vm._v("No Annotation")]
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "btn-mini showData",
                            attrs: { size: "sm", variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                _vm.showVariantData(data.item.varId)
                                data.toggleDetails()
                              },
                            },
                          },
                          [
                            !!_vm.loadingData[data.item.varId]
                              ? _c(
                                  "span",
                                  [
                                    _c("b-spinner", { attrs: { small: "" } }),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.detailsShowing ? "Hide" : "Show"
                                      ) +
                                      " Annotations"
                                  ),
                                ]),
                          ]
                        ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("div", { staticClass: "details" }, [
                      _vm.variantData[_vm.escapedVarID(row.item.varId)] &&
                      _vm.variantData[_vm.escapedVarID(row.item.varId)].length
                        ? _c(
                            "div",
                            [
                              _c("b-table", {
                                attrs: {
                                  items:
                                    _vm.variantData[
                                      _vm.escapedVarID(row.item.varId)
                                    ],
                                  fields: _vm.subFields,
                                  "per-page": _vm.perPage,
                                  "tbody-tr-class": _vm.rowPickClass,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(varId)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: `/variant.html?variant=${data.item.varId}`,
                                              },
                                            },
                                            [_vm._v(_vm._s(data.item.varId))]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "head(transcriptId)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "external_source" },
                                            [_vm._v("Feature ")]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transcriptId)",
                                      fn: function (data) {
                                        return [
                                          data.item.transcriptId
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: `https://grch37.ensembl.org/Homo_sapiens/Transcript/Summary?db=core;t=${data.item.transcriptId}`,
                                                    target: "_blank",
                                                    rel: "noopener noreferrer nofollow",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data.item.transcriptId
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(position)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item.proteinStart !==
                                                  data.item.proteinEnd
                                                  ? `${data.item.proteinStart}-${data.item.proteinEnd}`
                                                  : data.item.proteinStart
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(consequenceTerms)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "border-color",
                                              class: data.item.impact,
                                            },
                                            _vm._l(
                                              data.item.consequenceTerms,
                                              function (c, i) {
                                                return _c("span", { key: c }, [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.consequenceFormatter(
                                                        c
                                                      )
                                                    ) +
                                                      _vm._s(
                                                        i <
                                                          data.item
                                                            .consequenceTerms
                                                            .length -
                                                            1
                                                          ? ", "
                                                          : ""
                                                      )
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(siftPrediction)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.siftFormatter(
                                                  data.item.siftPrediction
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm.variantData[_vm.escapedVarID(row.item.varId)] &&
                          _vm.variantData[_vm.escapedVarID(row.item.varId)]
                            .length === 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "warning" } },
                                [
                                  _vm._v(
                                    " No predicted transcript consequences found for this variant."
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            attrs: {
              size: "sm",
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "aria-controls": "my-table",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }