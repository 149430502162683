var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "disease-group-select" },
    [
      _c(
        "b-form-select",
        {
          on: {
            change: function ($event) {
              return _vm.changeDiseaseGroup(_vm.selectedPortal)
            },
          },
          model: {
            value: _vm.selectedPortal,
            callback: function ($$v) {
              _vm.selectedPortal = $$v
            },
            expression: "selectedPortal",
          },
        },
        _vm._l(_vm.visibleDiseaseGroups, function (group) {
          return _c(
            "option",
            { key: group.name, domProps: { value: group.name } },
            [_vm._v(" " + _vm._s(group.description) + " ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }