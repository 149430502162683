var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "forest-plot-simple" }, [
    _c("div", { staticClass: "forest-plot-html-row" }, [
      _c("div", { staticClass: "plot" }, [
        _c("div", {
          staticClass: "forest-plot-html-item",
          style: "width:" + _vm.width + "%; left:" + _vm.startPos + "%;",
        }),
        _c(
          "div",
          {
            staticClass: "beta-box",
            style:
              (_vm.effect > 100 ? "display:none; " : "") +
              "left:calc(" +
              _vm.effectPos +
              "% - 6px);",
          },
          [_vm._v("   ")]
        ),
      ]),
      _c("div", { staticClass: "beta-0", staticStyle: { left: "50%" } }, [
        _c("label", [_vm._v(_vm._s(_vm.dichotomous ? "1" : "0"))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }