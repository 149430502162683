var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.datasetsListNew.length > 0
      ? _c("h3", { staticStyle: { "margin-top": "30px" } }, [
          _vm._v(" New Datasets "),
          _c("small", { staticClass: "datasets-list-header-small" }, [
            _vm._v("(Click datasets for description)"),
          ]),
        ])
      : _vm._e(),
    _vm.datasetsListNew.length > 0
      ? _c("div", { staticClass: "new datasets-list-table" }, [
          _c("table", { staticClass: "table table-hover table-sm" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticClass: "column name",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("description")
                      },
                    },
                  },
                  [_vm._v(" Dataset ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column access",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("access")
                      },
                    },
                  },
                  [_vm._v(" Access ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column samples",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("subjects")
                      },
                    },
                  },
                  [_vm._v(" Samples ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column ancestry",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("ancestry_name")
                      },
                    },
                  },
                  [_vm._v(" Ancestry ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column type",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("data_type")
                      },
                    },
                  },
                  [_vm._v(" Technology ")]
                ),
                _vm.diseaseGroup.name == "md" || _vm.diseaseGroup.name == "a2f"
                  ? _c("th", { staticClass: "column disease-group" }, [
                      _vm._v(" Contributing community "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.datasetsListNew, function (row, i) {
                return _c("tr", [
                  _c("td", { staticClass: "column name" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "/dinspector.html?dataset=" + row.name },
                      },
                      [_vm._v(_vm._s(row.description))]
                    ),
                  ]),
                  _c("td", { staticClass: "column access" }, [
                    _c("span", { class: row.access }, [
                      _vm._v(_vm._s(row.access)),
                    ]),
                  ]),
                  _c("td", { staticClass: "column samples" }, [
                    _vm._v(_vm._s(row.subjects)),
                  ]),
                  _c("td", { staticClass: "column ancestry" }, [
                    _vm._v(" " + _vm._s(row.ancestry_name) + " "),
                  ]),
                  _c("td", { staticClass: "column type" }, [
                    _vm._v(_vm._s(row.data_type)),
                  ]),
                  _vm.diseaseGroup.name == "md" ||
                  _vm.diseaseGroup.name == "a2f"
                    ? _c("td", { staticClass: "column disease-group" }, [
                        _c(
                          "span",
                          {
                            staticClass: "community-icon",
                            class: row.community,
                            attrs: { title: row.community },
                          },
                          [_vm._v(" ")]
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
    _vm.datasetsListNotNew.length > 0
      ? _c("h3", [
          _vm._v(" Datasets "),
          _c("small", { staticClass: "datasets-list-header-small" }, [
            _vm._v("(Click datasets for description)"),
          ]),
        ])
      : _vm._e(),
    _vm.datasetsListNotNew.length > 0
      ? _c("div", { staticClass: "datasets-list-table" }, [
          _c("table", { staticClass: "table table-hover table-sm" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticClass: "column name",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("description")
                      },
                    },
                  },
                  [_vm._v(" Dataset ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column access",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("access")
                      },
                    },
                  },
                  [_vm._v(" Access ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column samples",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("subjects")
                      },
                    },
                  },
                  [_vm._v(" Samples ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column ancestry",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("ancestry_name")
                      },
                    },
                  },
                  [_vm._v(" Ancestry ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "column type",
                    on: {
                      click: function ($event) {
                        return _vm.setSortKey("data_type")
                      },
                    },
                  },
                  [_vm._v(" Technology ")]
                ),
                _vm.diseaseGroup.name == "md" || _vm.diseaseGroup.name == "a2f"
                  ? _c("th", { staticClass: "column disease-group" }, [
                      _vm._v(" Contributing community "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.datasetsListNotNew, function (row, i) {
                return _c("tr", [
                  _c("td", { staticClass: "column name" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "/dinspector.html?dataset=" + row.name },
                      },
                      [_vm._v(_vm._s(row.description))]
                    ),
                  ]),
                  _c("td", { staticClass: "column access" }, [
                    _c("span", { class: row.access }, [
                      _vm._v(_vm._s(row.access)),
                    ]),
                  ]),
                  _c("td", { staticClass: "column samples" }, [
                    _vm._v(_vm._s(row.subjects)),
                  ]),
                  _c("td", { staticClass: "column ancestry" }, [
                    _vm._v(" " + _vm._s(row.ancestry_name) + " "),
                  ]),
                  _c("td", { staticClass: "column type" }, [
                    _vm._v(_vm._s(row.data_type)),
                  ]),
                  _vm.diseaseGroup.name == "md" ||
                  _vm.diseaseGroup.name == "a2f"
                    ? _c("td", { staticClass: "column disease-group" }, [
                        _c(
                          "span",
                          {
                            staticClass: "community-icon",
                            class: row.community,
                            attrs: { title: row.community },
                          },
                          [_vm._v(" ")]
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }