var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        key: `features_${_vm.index}`,
        staticClass: "feature-content-wrapper",
        class: [`feature-headers-${_vm.index}`, _vm.isHidden ? "hidden" : ""],
      },
      [
        _c(
          "b-row",
          { staticClass: "feature-header" },
          [
            _vm._l(_vm.colNames, function (col) {
              return _c(
                "b-col",
                { key: col, staticClass: "feature-header-item" },
                [_vm._v(_vm._s(col))]
              )
            }),
            !_vm.dichotomous
              ? _c("b-col", { staticClass: "feature-header-item" }, [
                  _vm._v("Beta"),
                ])
              : _c("b-col", { staticClass: "feature-header-item" }, [
                  _vm._v("Odds Ratio"),
                ]),
          ],
          2
        ),
        _vm._l(_vm.formattedMasks, function (mask, j) {
          return [
            _c(
              "b-row",
              {
                key: `features_${_vm.index}_${j}`,
                staticClass: "features",
                class: `features_${_vm.index}_${j}`,
              },
              [
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(mask.mask)),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(_vm.pValueFormatter(mask.pValue))),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(Number.parseFloat(mask.combinedAF).toFixed(7))),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(mask.passingVariants)),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(mask.singleVariants)),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(Number.parseFloat(mask.stdErr).toFixed(5))),
                ]),
                _c("b-col", { staticClass: "feature-content-item" }, [
                  _vm._v(_vm._s(_vm.intFormatter(mask.n))),
                ]),
                !_vm.dichotomous
                  ? _c("b-col", { staticClass: "feature-content-item" }, [
                      _c(
                        "span",
                        {
                          class:
                            mask.beta < 0
                              ? "effect negative"
                              : "effect positive",
                        },
                        [_vm._v(_vm._s(mask.beta < 0 ? "▼" : "▲"))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.effectFormatter(mask.beta)) + " "
                      ),
                    ])
                  : _c("b-col", { staticClass: "feature-content-item" }, [
                      _c(
                        "span",
                        {
                          class:
                            Math.exp(mask.beta) < 1
                              ? "effect negative"
                              : "effect positive",
                        },
                        [_vm._v(_vm._s(Math.exp(mask.beta) < 1 ? "▼" : "▲"))]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.effectFormatter(Math.exp(mask.beta))) +
                          " "
                      ),
                    ]),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        key: `plot_${_vm.index}`,
        staticClass: "feature-plot-wrapper",
        class: [`feature-plot-${_vm.index}`, _vm.isHidden ? "hidden" : ""],
      },
      [
        _c("b-col", [_vm._v("Forest Plot")]),
        _c("forest-plot", {
          ref: `fplot_${_vm.index}`,
          attrs: {
            id: `fplot_${_vm.index}`,
            data: _vm.formattedMasks,
            element: `fplot_${_vm.index}`,
            dichotomous: _vm.dichotomous,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }