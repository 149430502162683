var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.$store.state.selectedAncestry,
          expression: "$store.state.selectedAncestry",
        },
      ],
      ref: "ancestrySelect",
      staticClass: "form-control",
      on: {
        change: [
          function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.$set(
              _vm.$store.state,
              "selectedAncestry",
              $event.target.multiple ? $$selectedVal : $$selectedVal[0]
            )
          },
          function ($event) {
            return _vm.onAncestrySelected()
          },
        ],
      },
    },
    [
      _c("option", { attrs: { value: "", selected: "" } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.defaultMixed ? "Mixed (meta-analysis)" : "All ancestries"
            ) +
            " "
        ),
      ]),
      _vm._l(_vm.ancestryOptions, function (ancestry) {
        return _c("option", { domProps: { value: ancestry } }, [
          _vm._v(_vm._s(_vm.ancestryFormatter(ancestry))),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }