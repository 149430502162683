<template>
    <div class="mat-footer f-row align-h-center" style="font-size: 14px;">
        matkp development build
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.component("matkp-footer", {
    components: {
    },
    props: [],
    data() {
        return {
            
        };
    },
    computed: {
    },
    created() {
    },
    methods: {
    },
});
</script>