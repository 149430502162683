var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.currentPage == "huge calculator"
        ? [
            _c("div", { staticClass: "row", attrs: { id: "suggestionBox" } }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _vm._v(" HuGE Score (Combined Evidence) "),
                  _c("tooltip-documentation", {
                    attrs: {
                      name: "hugecal.combined.tooltip.hover",
                      contentFill: _vm.docDetails,
                      contentMap: _vm.$store.state.bioPortal.documentations,
                      isHover: true,
                      noIcon: false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-md-6",
                  staticStyle: {
                    "text-align": "right",
                    "white-space": "nowrap",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.commonVarBF) +
                      "(Common variation BF) * " +
                      _vm._s(_vm.rareVarBF.rareBF) +
                      "(Rare variation BF) = " +
                      _vm._s(_vm.hugeScore) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "lead block-end" }, [
              _vm._v(
                " *BF=Bayes Factor   *HuGE Score(combined evidence) = BF of common variation X BF of rare variation "
              ),
            ]),
            _c("hugescore-table", {
              attrs: {
                commonBF: _vm.commonVarBF,
                rareBF: _vm.rareVarBF.rareBF,
                hugeScore: _vm.hugeScore,
                exomeSignificant: _vm.isExomeWideSignificant(
                  _vm.rareAssociations
                ),
              },
            }),
          ]
        : _vm._e(),
      _vm.currentPage != "huge calculator"
        ? [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "center" },
                [
                  _c(
                    "b-table-simple",
                    { attrs: { borderless: "", fixed: "", small: "" } },
                    [
                      _c(
                        "b-tbody",
                        [
                          _c(
                            "b-tr",
                            [
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: {
                                    width: "30px",
                                    "background-color": "#e7edf7",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.commonVarBF))]
                              ),
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "15px" },
                                },
                                [_vm._v("X")]
                              ),
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: {
                                    width: "30px",
                                    "background-color": "#fef8dc",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.rareVarBF.rareBF))]
                              ),
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "15px" },
                                },
                                [_vm._v("=")]
                              ),
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: {
                                    width: "30px",
                                    "background-color": "#c4edc8",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.hugeScore))]
                              ),
                              _c(
                                "b-td",
                                {
                                  staticClass: "text-left",
                                  staticStyle: { width: "20px" },
                                },
                                [_vm._v("<-- HuGE Score")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "container",
                staticStyle: { "margin-bottom": "25px" },
              },
              [
                _c(
                  "ul",
                  {
                    staticClass: "legend center",
                    staticStyle: { "white-space": "nowrap" },
                  },
                  [
                    _vm._m(0),
                    _vm._m(1),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `/hugecalculator.html?gene=${_vm.selectedGene}&phenotype=${_vm.selectedPhenotype}`,
                          },
                        },
                        [_vm._v("View evidence in HuGE calculator >>")]
                      ),
                    ]),
                  ]
                ),
                _c("br"),
              ]
            ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "container color-bar" }, [
        _c(
          "div",
          { staticClass: "center" },
          [
            _vm.rareVarBF.rareBF
              ? _c("color-bar-plot", {
                  staticClass: "block-end",
                  attrs: {
                    category: _vm.getCategory(_vm.hugeScore),
                    elementid: "combinedVariation",
                    score: _vm.hugeScore,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("span", { staticClass: "superawesome" }),
      _vm._v(" Common Variation Bayes Factor "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("span", { staticClass: "awesome" }),
      _vm._v(" Rare Variation Bayes Factor "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }