var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.size,
          expression: "size",
        },
      ],
      staticClass: "form-control",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.size = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        },
      },
    },
    [
      _c("option", { attrs: { value: "small" } }, [_vm._v("Small")]),
      _c("option", { attrs: { value: "large" } }, [_vm._v("Large")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }