var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "heatmap-wrapper" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "heatmap-content",
        attrs: { id: "heatmapContent", hidden: this.hideHeatmap },
      },
      [
        !!_vm.renderConfig.label
          ? _c("div", {
              staticClass: "heatmap-label",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.label) },
            })
          : _vm._e(),
        !!_vm.renderConfig.legend
          ? _c("div", {
              staticClass: "heatmap-legend",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
            })
          : _vm._e(),
        !!_vm.renderConfig.legend
          ? _c("div", {
              staticClass: "heatmap-scale-legend",
              attrs: { id: "heatmap_scale_legend" },
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "heatmap-canvas-wrapper",
            attrs: { id: "heatmapPlotWrapper" },
          },
          [
            _c("div", {
              staticClass: "heatmap-columns-wrapper",
              attrs: { id: "heatmapColumnsWrapper" },
            }),
            _c("div", {
              staticClass: "heatmap-rows-wrapper",
              attrs: { id: "heatmapRowsWrapper" },
            }),
            _c(
              "div",
              {
                staticClass: "heatmap-canvas-wrapper",
                attrs: { id: "heatmapCanvasWrapper" },
              },
              [
                !!_vm.renderConfig
                  ? _c("canvas", {
                      attrs: { id: "heatmap", width: "", height: "" },
                      on: {
                        mouseleave: _vm.hidePanel,
                        mousemove: _vm.checkPosition,
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "clicked-cell-value hidden",
        attrs: { id: "clicked_cell_value" },
      },
      [_c("div", { attrs: { id: "clicked_cell_value_content" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }