var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("vue-typeahead-bootstrap", {
        ref: "phenotypeSelect",
        attrs: {
          placeholder: "Type in a phenotype ...",
          data: _vm.phenotypeOptions,
          serializer: (s) => s.description,
          maxMatches: 1000,
          minMatchingChars: 0,
          showOnFocus: true,
        },
        on: {
          hit: function ($event) {
            return _vm.onSecondaryPhenotypeSelected($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "suggestion",
            fn: function ({ data, htmlText }) {
              return [
                _c("span", { domProps: { innerHTML: _vm._s(htmlText) } }),
                _vm._v("  "),
                _c("small", { staticClass: "text-secondary" }, [
                  _vm._v(_vm._s(data.group)),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.userText,
          callback: function ($$v) {
            _vm.userText = $$v
          },
          expression: "userText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }