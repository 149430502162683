import { render, staticRenderFns } from "./ResearchBarPlotVector.vue?vue&type=template&id=5ab56a04"
import script from "./ResearchBarPlotVector.vue?vue&type=script&lang=js"
export * from "./ResearchBarPlotVector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/Portal (Dev)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ab56a04')) {
      api.createRecord('5ab56a04', component.options)
    } else {
      api.reload('5ab56a04', component.options)
    }
    module.hot.accept("./ResearchBarPlotVector.vue?vue&type=template&id=5ab56a04", function () {
      api.rerender('5ab56a04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/researchPortal/vectorPlots/ResearchBarPlotVector.vue"
export default component.exports