var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "criterion-group-template",
    {
      ref: Math.floor(Math.random() * 10000).toString(),
      attrs: {
        value: _vm.value,
        hide: _vm.hide,
        filterType: "list",
        looseMatch: true,
        header: _vm.header,
        filterList: _vm.filterList,
        filterFunction: _vm.filterFunction,
      },
      on: {
        input: _vm.emitInput,
        "update:filter-function": _vm.emitFilterFunction,
        "update:filter-list": _vm.emitFilterList,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "filtered",
            fn: function ({ filter }) {
              return [_vm._t("filtered", null, { filter: filter })]
            },
          },
        ],
        null,
        true
      ),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }