var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "col", staticStyle: { padding: "5px 7px 5px 7px" } },
    [
      _vm._t("default", function () {
        return [_vm._v(" " + _vm._s(_vm.field) + " ")]
      }),
      !!_vm.options && Array.isArray(_vm.options)
        ? _c("autocomplete", {
            attrs: {
              matches: _vm.options,
              labelFormatter: _vm.labelFormatter,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
            },
            on: {
              "item-select": function ($event) {
                return _vm.updateFilter($event)
              },
              "input-change": function ($event) {
                return _vm.$parent.$emit("input-change", $event)
              },
            },
          })
        : _c("b-form-input", {
            attrs: { disabled: _vm.disabled, placeholder: _vm.placeholder },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.updateFilter(_vm.filterThreshold)
              },
            },
            model: {
              value: _vm.filterThreshold,
              callback: function ($$v) {
                _vm.filterThreshold = $$v
              },
              expression: "filterThreshold",
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }