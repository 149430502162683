var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.recentNews, function (item, itemIndex) {
      return _c(
        "div",
        { key: item.nid, staticClass: "news" },
        [
          itemIndex < 5
            ? [
                _c("div", { staticClass: "thumbnail" }, [
                  item.field_image != ""
                    ? _c("img", { attrs: { src: item.field_image } })
                    : _vm._e(),
                ]),
                _c("h5", [_vm._v(_vm._s(item.title))]),
                _c("div", { staticClass: "news-body" }, [
                  _vm._v(_vm._s(item.body)),
                ]),
                _c("span", [
                  _vm._v(" ... "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "news.html?nid=" + item.nid,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Read more")]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }