var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return this.pageInfo[0]
    ? _c("div", {
        staticClass: "page-info-wrapper row",
        domProps: { innerHTML: _vm._s(this.pageInfo[0].body[0].value) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }