var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "no-access" },
    [
      _vm.restricted
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm.count
              ? _c(
                  "span",
                  [
                    _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                    _vm._v(" There were "),
                    _c("strong", [_vm._v(_vm._s(_vm.count))]),
                    _vm._v(
                      " records hidden because you do not have required permission to view them. "
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                    _vm._v(
                      " There was hidden data because you do not have required permission to view them. "
                    ),
                  ],
                  1
                ),
            !!_vm.user
              ? _c("span", [
                  _vm._v(
                    " Please contact us if you believe you should've given access. "
                  ),
                ])
              : _c("span", [
                  _vm._v(" Please "),
                  _c(
                    "a",
                    {
                      attrs: { href: "/login" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.loginUser.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("log in")]
                  ),
                  _vm._v(" with an authorized Google account to see them. "),
                ]),
          ])
        : !!_vm.failed
        ? _c(
            "b-alert",
            { attrs: { show: "", variant: "danger" } },
            [
              _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
              _vm._v(
                " There was an error loading the data: " +
                  _vm._s(_vm.failed) +
                  " "
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }