var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("download-chart", {
          attrs: {
            filename: !_vm.plotName ? "scatterplot" : _vm.plotName,
            chartId: `chart-${_vm.plotId}`,
          },
        }),
        _c("div", { attrs: { id: _vm.plotId } }, [
          _c("p", [_vm._v("Loading...")]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }