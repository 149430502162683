var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", [
    _c("div", { attrs: { id: `lz_${_vm.salt}` } }, [
      _c("div", [_vm.locuszoommounted ? _vm._t("default") : _vm._e()], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }