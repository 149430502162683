var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.traitGroup,
          expression: "traitGroup",
        },
      ],
      staticClass: "form-control",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.traitGroup = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    [
      _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
      _c("option", { attrs: { value: "portal" } }, [_vm._v("A2F")]),
      _c("option", { attrs: { value: "gcat_trait" } }, [
        _vm._v("GWAS Catalog"),
      ]),
      _c("option", { attrs: { value: "rare_v2" } }, [_vm._v("Orphanet")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }