var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mat-footer f-row align-h-center",
      staticStyle: { "font-size": "14px" },
    },
    [_vm._v(" matkp development build ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }