var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "filtering-ui-wrapper container-fluid search-header" },
    [
      _c(
        "div",
        {
          staticClass: "row filtering-ui-content search-header-content",
          attrs: { id: "pageSearchHeaderContent" },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }