var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !!_vm.filterList && _vm.filterList.length > 0
    ? _c(
        "span",
        { staticClass: "filter-pill-collection center" },
        [
          _vm._v(" " + _vm._s(_vm.header) + " "),
          _vm._l(_vm.filterList, function (filter, idx) {
            return _c(
              "b-badge",
              {
                key: filter.field + filter.predicate + filter.threshold + idx,
                class: `btn filter-pill-${filter.field}`,
                style: {
                  "background-color": `${
                    !!filter.color ? `${filter.color} !important` : ""
                  }`,
                },
                attrs: { pill: "" },
                on: {
                  click: function ($event) {
                    _vm.clearable ? _vm.$emit("unset", filter) : () => {}
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !!filter.label
                        ? typeof filter.label === "function"
                          ? filter.label(filter)
                          : new String(filter.label)
                        : `${filter.field} = ${filter.threshold}`
                    ) +
                    " "
                ),
                _vm.clearable
                  ? _c("span", { staticClass: "remove" }, [_vm._v("X")])
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }