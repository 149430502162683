var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "network-container", style: _vm.containerStyle },
    [
      _vm.error
        ? _c("div", { staticClass: "error-alert" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      !_vm.isEmbed
        ? [
            _c(
              "button",
              {
                staticClass: "btn btn-sm control-button physics-button",
                attrs: { disabled: _vm.stabilizing },
                on: { click: _vm.togglePhysics },
              },
              [
                _c("b-icon", {
                  attrs: {
                    icon: _vm.physicsEnabled ? "toggle-on" : "toggle-off",
                  },
                }),
                _vm._v(" Physics "),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-sm control-button nav-button",
                on: { click: _vm.toggleNavigation },
              },
              [
                _c("b-icon", {
                  attrs: { icon: !_vm.showNavigation ? "eye-slash" : "eye" },
                }),
                _vm._v(" Navigation "),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-sm control-button fullscreen-button",
                on: { click: _vm.toggleFullscreen },
              },
              [
                _c("b-icon", {
                  attrs: {
                    icon: _vm.isFullscreen ? "fullscreen-exit" : "fullscreen",
                  },
                }),
                _vm._v(" Fullscreen "),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading && !_vm.stabilizing,
            expression: "!loading && !stabilizing",
          },
        ],
        ref: "networkContainer",
        staticClass: "vis-network",
      }),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [_vm._v("Loading data...")])
        : _vm._e(),
      _vm.stabilizing
        ? _c("div", { staticClass: "stabilization-progress" }, [
            _c("div", { staticClass: "progress-bar" }, [
              _c("div", {
                staticClass: "progress-fill",
                style: { width: `${_vm.stabilizationProgress}%` },
              }),
            ]),
            _c("div", { staticClass: "progress-text" }, [
              _vm._v(
                " " + _vm._s(Math.round(_vm.stabilizationProgress)) + "% "
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }