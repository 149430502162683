var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mat-header f-row spread-out align-v-center",
      class: _vm.glass ? "glass" : "",
    },
    [_vm._m(0), _vm._m(1)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      { staticClass: "logo f-row align-v-center", attrs: { href: "/matkp/" } },
      [
        _c("img", {
          attrs: {
            src: "https://hugeampkpncms.org/sites/default/files/users/user32/matkp/matkplll.png",
          },
        }),
        _c("span", { staticStyle: { "font-weight": "600" } }, [
          _vm._v("MAT"),
          _c("span", { staticStyle: { "font-weight": "300" } }, [_vm._v("KP")]),
          _c("span", { staticClass: "tagline" }, [
            _vm._v("The place for fat."),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "f-row menu" }, [
      _c("a", { attrs: { href: "/matkp/datasets.html" } }, [
        _vm._v("Datasets"),
      ]),
      _c("a", { attrs: { href: "/matkp/cellbrowser2.html" } }, [
        _vm._v("Cell Browser"),
      ]),
      _c("a", { attrs: { href: "/matkp/" } }, [_vm._v("Help")]),
      _c("a", { attrs: { href: "/matkp/" } }, [_vm._v("Collaborate")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }