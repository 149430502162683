import { render, staticRenderFns } from "./ResearchSingleSearch.vue?vue&type=template&id=729f2d9a&scoped=true"
import script from "./ResearchSingleSearch.vue?vue&type=script&lang=js"
export * from "./ResearchSingleSearch.vue?vue&type=script&lang=js"
import style0 from "./ResearchSingleSearch.vue?vue&type=style&index=0&id=729f2d9a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729f2d9a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/Portal (Dev)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('729f2d9a')) {
      api.createRecord('729f2d9a', component.options)
    } else {
      api.reload('729f2d9a', component.options)
    }
    module.hot.accept("./ResearchSingleSearch.vue?vue&type=template&id=729f2d9a&scoped=true", function () {
      api.rerender('729f2d9a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/researchPortal/ResearchSingleSearch.vue"
export default component.exports