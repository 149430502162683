var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-right mt-2 mb-2" },
        [
          _c("data-download", {
            attrs: { data: _vm.tableData, filename: "gene_associations" },
          }),
        ],
        1
      ),
      _vm.gene && _vm.rows > 0
        ? _c("b-table", {
            attrs: {
              hover: "",
              small: "",
              responsive: "sm",
              items: _vm.tableData,
              fields: _vm.fields,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(phenotype)",
                  fn: function (r) {
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "phenotype-gene-association",
                          attrs: { href: "javascript:;" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.phenotypeFormatter(
                                  _vm.phenotypeMap[r.item.phenotype]
                                )
                              ) +
                              " "
                          ),
                          _c("div", { staticClass: "options-4-actions" }, [
                            _c("div", [
                              _vm.phenotypeMap
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `/phenotype.html?phenotype=${r.item.phenotype}`,
                                      },
                                    },
                                    [_vm._v("Open phenotype page")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _vm.phenotypeMap
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `/region.html?phenotype=${r.item.phenotype}&chr=${_vm.gene.chromosome}&start=${_vm.gene.start}&end=${_vm.gene.end}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Open region page with selected phenotype"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v("   "),
                    ]
                  },
                },
              ],
              null,
              false,
              3638929535
            ),
          })
        : _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _c("b-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v(" No data available for this query. "),
                ],
                1
              ),
            ],
            1
          ),
      _c("b-pagination", {
        staticClass: "pagination-sm justify-content-center",
        attrs: { "total-rows": _vm.rows, "per-page": _vm.perPage },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }