var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "filter-control-template",
    {
      staticClass: "filter-col-sm",
      attrs: {
        field: _vm.field,
        type: "number",
        predicate: _vm.predicate,
        pillFormatter: _vm.pillFormatter,
        color: _vm.color,
        computedField: _vm.computedField,
        multiple: false,
      },
    },
    [
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.field))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }