var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "chartWrapper" }, [
    _c("div", { ref: "chart" }),
    _c("div", { ref: "tooltip", staticClass: "tooltip" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }