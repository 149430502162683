var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "EventListener",
        {
          on: {
            change: _vm.filterControlChange,
            "filter-mounted": _vm.onInitialFilterDefinition,
          },
        },
        [
          _vm._t("header"),
          _c(
            "b-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hide,
                  expression: "!hide",
                },
              ],
              staticClass: "filtering-ui-wrapper",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-row",
                { staticClass: "filtering-ui-content" },
                [
                  _vm._t("default"),
                  _vm.inlinePills && !_vm.noPills
                    ? _vm._t("pills", function () {
                        return [
                          _vm.filterListInternal != null &&
                          _vm.filterListInternal.length > 0
                            ? _c("criterion-pills", {
                                attrs: {
                                  header: _vm.header,
                                  fitlerList: _vm.filterListInternal,
                                  clearable: _vm.clearable,
                                },
                                on: { unset: _vm.unsetFilter },
                              })
                            : _vm._e(),
                        ]
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          !_vm.inlinePills && !_vm.noPills
            ? _vm._t("pills", function () {
                return [
                  _vm.filterListInternal != null &&
                  _vm.filterListInternal.length > 0
                    ? _c("criterion-pills", {
                        attrs: {
                          header: _vm.header,
                          filterList: _vm.filterListInternal,
                          clearable: _vm.clearable,
                        },
                        on: { unset: _vm.unsetFilter },
                      })
                    : _vm._e(),
                ]
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._t("filtered", null, { filter: _vm.criterion }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }